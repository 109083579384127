import { Configuration, ConfigurationObject } from "./shared";

const { REACT_APP_ENV = "dev" } = process.env;
const dev: Configuration = {
  env: REACT_APP_ENV,
  baseApiUrl: "http://localhost:4000/api/v1",
  applicationUrl: "http://localhost:3000",
  rtmpUrl: "rtmp://localhost",
  getStreamToken: "geu5kskvnda5",
  wsApiUrl: "http://localhost:4000",
  wsPath: "/rtc",
  zoomApiKey: "EMm6RGSLRZKLbRzjw1wnBQ",
  sentryDSN: "https://7a6ccbd186e74ff7913f2c21257e64e5@o224005.ingest.sentry.io/5409384",
  appLink: {
    participantIos: "https://apps.apple.com/us/app/id1527581574",
    participantAndroid: "https://play.google.com/store/apps/details?id=com.altarlive.participant",
    presenterIos: "https://apps.apple.com/us/app/id1523508666",
  },
  initFirebase: false,
  turnServer: {
    url: "3.82.125.80",
    port: "3478",
    username: "923digital",
    password: "v5vXH4pd8EWDqjgc",
  },
  facebook: {
    appId: "1008840872947553",
  },
  socialLoginClientId: {
    facebook: "149864526474383",
    google: "171630015056-ii8ln5henrshrg6gnhvp7eaaog07co47.apps.googleusercontent.com",
  },
  iceServers: [
    { urls: ["stun:us-turn10.xirsys.com"] },
    {
      username: "uJxxD8MM_6Ls_gO_D1RKzwuk3D5mYqSmA9vNS1jIOH_W-v69KD2vov-AlgtEojBrAAAAAGA1GxxhbHRhcmxpdmU=",
      credential: "64a53e8e-75e9-11eb-b1ae-0242ac140004",
      urls: [
        "turn:us-turn10.xirsys.com:80?transport=udp",
        "turn:us-turn10.xirsys.com:3478?transport=udp",
        "turn:us-turn10.xirsys.com:80?transport=tcp",
        "turn:us-turn10.xirsys.com:3478?transport=tcp",
        "turns:us-turn10.xirsys.com:443?transport=tcp",
        "turns:us-turn10.xirsys.com:5349?transport=tcp",
      ],
    },
  ],
  subdomainPartUrl: ".localhost",
  eightToeight: {
    appId: "vpaas-magic-cookie-2b3451eab5e94dcb9c4c79f838b74d5b",
    domain: "8x8.vc",
    toolbarButtons: [
      "microphone",
      "hangup",
      "camera",
      //   "closedcaptions",
      "desktop",
      "fullscreen",
      "fodeviceselection",
      //"recording",
      // "livestreaming",
      "etherpad",
      "settings",
      "videoquality",
      "filmstrip",
      //   "stats",
      //    "shortcuts",
      "tileview",
      "select-background",
      "raisehand",
      "download",
      "mute-everyone",
      "mute-video-everyone",
      "sharedvideo",
      "shareaudio",
    ],
  },
  rewardfulAppId: "",
  heapAnalyticsId: "",
  hubspotScriptUrl: "//js-eu1.hs-scripts.com/25027498.js",
  vimeo: {
    client_id: "0a613d59361b74e892ec1a0d998368ea4f1f84f0",
    redirect_url: "http://localhost:4000/api/v1/community/createVimeoToken",
  },
  s3: {
    altarFilesCloudFrontDomain: "https://cdn.altarlive.com",
  },
};

const stage: Configuration = {
  ...dev,
  baseApiUrl: "https://api.stage.altarlive.com/api/v1",
  applicationUrl: "https://app.stage.altarlive.com",
  wsApiUrl: "https://api.stage.altarlive.com",
  subdomainPartUrl: ".stage.altarlive.com",
  rtmpUrl: "rtmp://media.stage.altar.link",
  vimeo: {
    client_id: "0a613d59361b74e892ec1a0d998368ea4f1f84f0",
    redirect_url: "https://api.stage.altarlive.com/api/v1/community/createVimeoToken",
  },
};

const production: Configuration = {
  ...dev,
  baseApiUrl: "https://api.altarlive.com/api/v1",
  applicationUrl: "https://app.altarlive.com",
  wsApiUrl: "https://api.altarlive.com",
  getStreamToken: "97bdkmzhdnyw",
  initFirebase: true,
  googleAdv: {
    conversionId: "AW-758660682",
    conversionLabel: "B_mpCMra_PEBEMr84OkC",
    measurementId: "G-B0L1C8HHQ4",
  },
  facebook: {
    appId: "730182941082643",
  },
  iceServers: [
    { urls: ["stun:us-turn7.xirsys.com"] },
    {
      username: "apSxovPbm_g-Hzu50bSWCdUhWYdEgCTXxlU9gSh4Ap8fcK0Oq_UnU6P3KP7RbZqvAAAAAGA2M1NhbHRhcmxpdmU=",
      credential: "6a83f9ce-7690-11eb-a484-0242ac140004",
      urls: [
        "turn:us-turn7.xirsys.com:80?transport=udp",
        "turn:us-turn7.xirsys.com:3478?transport=udp",
        "turn:us-turn7.xirsys.com:80?transport=tcp",
        "turn:us-turn7.xirsys.com:3478?transport=tcp",
        "turns:us-turn7.xirsys.com:443?transport=tcp",
        "turns:us-turn7.xirsys.com:5349?transport=tcp",
      ],
    },
  ],
  subdomainPartUrl: ".altarlive.com",
  rtmpUrl: "rtmp://media.altar.link",
  rewardfulAppId: "b664b0",
  heapAnalyticsId: "3183015798",
  hubspotScriptUrl: "//js-na1.hs-scripts.com/20564377.js",
  vimeo: {
    client_id: "0a613d59361b74e892ec1a0d998368ea4f1f84f0",
    redirect_url: "https://api.altarlive.com/api/v1/community/createVimeoToken",
  },
};

const test: Configuration = {
  ...dev,
  baseApiUrl: "https://api.test.altarlive.com/api/v1",
  applicationUrl: "https://app.test.altarlive.com",
  wsApiUrl: "https://api.test.altarlive.com",
  subdomainPartUrl: ".test.altarlive.com",
  rtmpUrl: "rtmp://media.test.altar.link",
  vimeo: {
    client_id: "0a613d59361b74e892ec1a0d998368ea4f1f84f0",
    redirect_url: "https://api.test.altarlive.com/api/v1/community/createVimeoToken",
  },
};

const qa: Configuration = {
  ...dev,
  baseApiUrl: "https://api.qa.altarlive.com/api/v1",
  applicationUrl: "https://app.qa.altarlive.com",
  wsApiUrl: "https://api.qa.altarlive.com",
  heapAnalyticsId: "3402681844",
  subdomainPartUrl: ".qa.altarlive.com",
  rtmpUrl: "rtmp://media.qa.altar.link",
  vimeo: {
    client_id: "0a613d59361b74e892ec1a0d998368ea4f1f84f0",
    redirect_url: "https://api.qa.altarlive.com/api/v1/community/createVimeoToken",
  },
};

const config: ConfigurationObject = {
  dev,
  stage,
  production,
  test,
  qa,
};

const configElement: Configuration = (config as any)[REACT_APP_ENV];
export default configElement;
